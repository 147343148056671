<template>
  <div class="box boxOnly">
    <v-card class="editorPadding _box">
      <div style="width: 100%">
        <v-row>
          <v-col cols="10">
            <S3_FileUpload_V4
              :visible="false"
              ref="refS3FileUploader"
              uploadType="file"
              uploadDir="file"
              fileHeader="beforeAfterFile"
              accept=".pdf, .jpg, .jpeg, .png, .gif"
              :fileInfo="fileInfo"
              @fileSelected="fileSelected"
              @uploadComplete="uploadComplete"
              @changeDeleteOpt="changeDeleteOpt"
            />
          </v-col>
        </v-row>

        <DataTable
          ref="DataTable"
          :loading="loading"
          :loadingText="loadingText"
          :tableKey="'rowSeq'"
          :footerHide="true"
          :singleSelect="true"
          :showSelect="false"
          :columns="tableColumns"
          :inputData="uploadedFiles"
          @imageClick="imageClick"
          @RemoveClick="removeClick"
        />
      </div>
    </v-card>

    <DialogImageViewer ref="refDialogImageViewer" />
  </div>
</template>
<script>
import S3_FileUpload_V4 from "@/components/commonV2/ui/S3_FileUpload_V4.vue";
import DataTable from "@/components/commonV2/table/DataTable.vue";
import DialogImageViewer from "@/components/commonV2/Dialog/DialogImageViewer.vue";
const formatService = require("@/utils/format.js");
const modelService = require("@/utils/dataModel");

export default {
  components: {
    S3_FileUpload_V4,
    DataTable,
    DialogImageViewer,
  },
  props: {
    propsUploadedFiles: {
      type: [Object, Array],
    },
    ownerData: {
      type: [Object, Array],
    },
    fileGbn: {
      type: [String],
      default: "beforeAfter",
    },
  },
  data() {
    return {
      loading: false,
      loadingText: "Loading list...",
      fileInfo: null,
      fileDeleteOpt: false,
      uploadedFiles: [],
      uploadDefaultDate: this.$helper.getToDateStringType(),
      uploadFilesModel: modelService.uploadFilesModel(),
      tableColumns: formatService.uploadFilesTableColumns(),
    };
  },
  watch: {
    propsUploadedFiles(val) {
      if (val === null) return;
      this.uploadedFiles = val;
    },
  },
  methods: {
    /** 외부에서 업로드 대상 파일 확인 */
    _checkTargetFiles() {
      this.$emit("checkTargetFiles", this.uploadedFiles);
    },
    fileSelected(e) {
      this.uploadFilesModel.fileOriginalName = e.name;
      this.$refs.refS3FileUploader.uploadFileToS3();
    },
    changeDeleteOpt(e) {
      this.fileDeleteRequestImg = e;
    },
    /** S3업로드 처리 후 */
    uploadComplete(e) {
      // console.log("uploadComplete", e);
      if (e === null) return;
      this.saveAction(e.Location);
    },
    /*** 업로드 후 */
    saveAction(location) {
      const fileInfo = {
        fileGbn: this.fileGbn,
        fileOriginalName: this.uploadFilesModel.fileOriginalName,
        fileUrl: location,
        regDateLabel: this.uploadDefaultDate,
        userType: "uploadFiles",
      };
      this.uploadedFiles.push(fileInfo);
      this.$emit("checkTargetFiles", this.uploadedFiles);
      // 초기화
      this.$refs.refS3FileUploader._resetForm();
    },
    removeClick(item) {
      console.log("removeClick", item);
      if (!confirm("삭제하시겠습니까?")) return;
      // fileUrl 로 같은 파일 검색
      const itemIdx = this.uploadedFiles
        .map((x) => x.fileUrl)
        .indexOf(item.fileUrl);
      console.log("itemIdx", itemIdx);

      // 삭제
      if (itemIdx > -1) {
        this.uploadedFiles.splice(itemIdx, 1);
      }
      this.$emit("checkTargetFiles", this.uploadedFiles, "removeClick");
    },
    imageClick(item) {
      if (!this.$helper.checkImageFile(item.fileUrl)) {
        window.open(item.fileUrl, "_blank");
        return;
      }
      /** 이미지 파일이면 보이게 처리 */
      this.$refs.refDialogImageViewer.Open(item.fileUrl);
    },
  },
};
</script>
<style lang="scss">
.boxOnly {
  width: 98% !important;
  ._box {
    padding-left: 0;
    padding-right: 0;
  }
  .inBox {
    width: 100% !important;
  }
}
</style>