<template>
  <div>
    <v-row> {{ label }} </v-row>
    <v-row>
      <PrepareFilesUpload
        ref="refPrepareFilesUpload"
        :propsUploadedFiles="uploadFiles"
        @checkTargetFiles="$emit('checkTargetFiles', $event)"
      />
    </v-row>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import PrepareFilesUpload from "@/components/commonV2/ui/PrepareFilesUpload.vue";
const modelService = require("@/utils/dataModel");

export default {
  components: {
    PrepareFilesUpload,
  },
  props: {
    label: {
      type: String,
      default: "Before & After"
    },
  },
  data() {
    return {
      uploadFiles: [],
      uploadFilesModel: modelService.uploadFilesModel(),
    };
  },
  methods: {
    async _getUploadFiles(clinicData, product_id) {
      this.uploadFilesModel.endpoint = "uploadFiles";
      this.uploadFilesModel.hospital_id = clinicData.hospital_id;
      this.uploadFilesModel.user_id = 0;
      this.uploadFilesModel.product_id = product_id;
      this.uploadFilesModel.fileGbn = "beforeAfter";
      await this.getDataQuery(this.uploadFilesModel).then((resP) => {
        if (resP === undefined) return;
        let res = resP.data;
        // console.table(res);
        if (0 < res.length) {
          res = res.map((item, index) => ({
            ...item,
            regDateLabel: this.$helper.changeDateToStringForDp(
              item.regDate,
              true
            ),
          }));
        }
        this.uploadFiles = res;
      });
    },
    ...mapActions(["getDataQuery", "saveDataQuery"]),
  },
};
</script>
<style lang="">
</style>