<template>
  <v-dialog v-model="visible" max-width="800px" scrollable>
    <v-card class="editorPadding">
      <Header @close="close" />

      <v-card-title> {{ editType }} </v-card-title>
      <v-card-text id="productInfo">
        <v-form ref="form">
          <TitleField 
            label="상품명" 
            :titleData="targetModel.productName" 
            :rules="[filedRules.required]" 
            @complete="targetModel.productName = $event"/>

          <!-- 공통상품관리로 변경처리 -->
          <v-row>
            <SelectBoxProductTypeAndItems 
              ref="refSelectBoxProductTypeAndItems"
              @typeSelectedComplete="typeItemSelectedComplete"
              @itemSelectedComplete="typeItemSelectedComplete"/>
          </v-row>
          
          <TitleField :titleData="targetModel.medicineName" label="약품/기기명" @complete="targetModel.medicineName = $event"/>

          <TitleField :titleData="targetModel.medicineOrigin" label="약품/기기 원산지" @complete="targetModel.medicineOrigin = $event"/>

          <!-- :rules="[filedRules.required]" -->
          <v-row>
            <v-text-field
              label="상품가격"
              type="text"
              ref="refProductPrice"
              v-model="targetModel.productPrice"
              placeholder=",없이 입력하세요"
            />
          </v-row>
          <v-row>
            <v-text-field
              label="판매가격"
              type="text"
              ref="refProductSalePrice"
              v-model="targetModel.productSalePrice"
              placeholder=",없이 입력하세요"
            />
          </v-row>
          <!-- <v-row>
            <v-text-field
              label="기기,약품소개"
              type="text"
              ref="refItemIntro"
              v-model="targetModel.itemIntro"
            />
          </v-row> -->
          <TextArea 
            label="기기,약품소개" 
            :titleData="targetModel.itemIntro" 
            @complete="targetModel.itemIntro = $event"/>

          <div class="vGap" />

          <TextArea 
            label="상품상세" 
            :titleData="targetModel.description" 
            :rules="[filedRules.required]"
            @complete="targetModel.description = $event"/>

          <div class="vGap" />

          <TextArea :titleData="targetModel.effect" label="효능, 기능" @complete="targetModel.effect = $event"/>

          <div class="vGap" />

          <!-- <v-row>
            <RadioComps
              :items="isCanTaxRefundItem"
              :prosSelected="isCanTaxRefund"
              @changeType="isCanTaxRefundChangeType"
            />
          </v-row> -->

          <BeforeAndAfterUpload
            label="상품정보 파일업로드"
            ref="refBeforeAndAfterUpload"
            @checkTargetFiles="checkTargetFiles"
          />
        </v-form>
      </v-card-text>

      <CommonBtn
        :propsKey="targetModel.product_id"
        @save="clickSave"
        @delete="deleteData"
        @cancel="clickCancel"
      />
    </v-card>
  </v-dialog>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import Header from "./comp/Header.vue";
import CommonBtn from "./medicalInfo/comp/CommonBtn.vue";
import SelectBox from "@/components/commonV2/ui/SelectBox.vue";
import BeforeAndAfterUpload from "./comp/BeforeAndAfterUpload.vue";

import TitleField from "@/components/commonV2/ui/lang/TitleField.vue";
import TextArea from "@/components/commonV2/ui/lang/TextArea.vue";
import SelectBoxProductTypeAndItems from "@/components/commonV2/ui/SelectBoxProductTypeAndItems.vue";
// import SelectBoxProductType from "@/components/commonV2/ui/SelectBoxProductType.vue";
// import SelectBoxProductTypeItem from "@/components/commonV2/ui/SelectBoxProductTypeItem.vue";
import RadioComps from "@/components/commonV2/ui/RadioComps.vue";
const rulesService = require("@/utils/rules.js");
const modelService = require("@/utils/dataModel.js");
const formatService = require("@/utils/format.js");
const requireService = require("@/utils/requiredValue.js");

export default {
  components: {
    Header,
    CommonBtn,
    SelectBox,
    BeforeAndAfterUpload,
    SelectBoxProductTypeAndItems,
    // SelectBoxProductType,
    // SelectBoxProductTypeItem,
    RadioComps,
    TitleField,
    TextArea
  },
  data() {
    return {
      visible: false,
      editType: "",
      selectedProductTypeId: 0,
      selectedProductTypeItemId: 0,
      itemsProductType: [], //formatService.productTypeList(),
      isCanTaxRefund: "Y",
      isCanTaxRefundItem: formatService.isCanTaxRefundItem(),
      serviceList: formatService.serviceList(),
      diagnosisList: formatService.diagnosisList(),
      requiredMsg: requireService.requiredFieldAlertMessage(),
      targetModel: modelService.hospitalProductModel(),
      filedRules: rulesService.filedRules(),
      clinicData: null,
      // 다국어
      productName: "",
    };
  },
  methods: {
    showModal(editType, clinicData, item) {
      this.clinicData = clinicData;
      this.visible = true;
      this.editType = editType;
      if (item === null) {
        this.targetModel = modelService.hospitalProductModel();
        this.selectedProductTypeId = 0;
        this.selectedProductTypeItemId = 0;
      } else {
        this.targetModel = item;
        this.selectedProductTypeId = item.type_id;
        this.selectedProductTypeItemId = item.item_id;
      }
      this.targetModel.hospital_id = clinicData.hospital_id;
      this.targetModel.endpoint = "hospitalProduct";

      setTimeout(() => {
        this.ScrollTop();
        // this.$refs.refSelectBoxProductType.getProductTypeData(clinicData);
        // this.$refs.refSelectBoxProductTypeItem.getProductTypeItemData(
        //   clinicData,
        //   this.selectedProductTypeId
        // );
        this.$refs.refSelectBoxProductTypeAndItems._initData(this.selectedProductTypeId, this.selectedProductTypeItemId);
        this.$refs.refBeforeAndAfterUpload._getUploadFiles(clinicData, item === null ? 0:item.product_id);
      }, 300);
    },
    close() {
      this.visible = false;
    },
    isCanTaxRefundChangeType(e) {
      this.targetModel.isCanTaxRefund = e;
    },
    typeItemSelectedComplete(type_id, item_id) {
      // console.log('type_id', type_id);
      // console.log('item_id', item_id);
      this.targetModel.type_id = type_id;
      this.targetModel.item_id = item_id;
    },
    // productTypeChangeSelect(e) {
    //   this.selectedProductTypeId = e;
    //   this.targetModel.type_id = e;
    //   this.$refs.refSelectBoxProductTypeItem.getProductTypeItemData(
    //     this.clinicData,
    //     e
    //   );
    // },
    // productTypeItemChangeSelect(e) {
    //   this.targetModel.item_id = e;
    // },
    checkTargetFiles(e) {
      this.targetModel.uploadFiles = e;
      // hospital_id / product_id추가
      if (this.targetModel.uploadFiles.length > 0) {
        this.targetModel.uploadFiles.forEach((element) => {
          element.hospital_id = this.targetModel.hospital_id;
          element.product_id = this.targetModel.product_id;
        });
      }
    },
    clickSave() {
      // console.log("saveData", this.targetModel);
      // return;
      const $h = this.$helper;
      const D = this.targetModel;
      let RF = this.$refs;

      let msg = `상품타입 ${this.requiredMsg}`;
      if (!$h.requiredCheck(D.type_id, null, msg, false)) return;

      msg = `상품아이템 ${this.requiredMsg}`;
      if (!$h.requiredCheck(D.item_id, null, msg, false)) return;

      // msg = `Drug Name ${this.requiredMsg}`;
      // if (!$h.requiredCheck(D.drugName, null, msg, false)) return;
      // console.log('D', D);
      // return;
      
      if (!this.$refs.form.validate()) return;

      // 가격정보 , -> 삭제
      D.productPrice = D.productPrice.toString().replaceAll(",", "");
      D.productSalePrice = D.productSalePrice.toString().replaceAll(",", "");
      // return;
      this.saveData();
    },
    clickCancel() {
      this.visible = false;
    },
    deleteData() {
      if (!confirm("delete?")) return;
      this.targetModel.useYN = "N";
      this.saveData();
    },
    saveData() {
      // console.log('saveData', this.targetModel);
      this.saveDataQuery(this.targetModel).then((res) => {
        if (res === undefined) return;
        this.$helper.showTostMessage("", "Saved", "success");
        this.visible = false;
        this.$emit("saveComplete");
      });
    },
    ScrollTop() {
      console.log("Scroll");
      const scrollTarget = document.getElementById("productInfo");
      scrollTarget.scrollTo({ top: 0, behavior: "smooth" });
    },
    ...mapActions(["saveDataQuery"]),
  },
};
</script>